import classNames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {BuilderContext} from 'contextes/builder';
import {any, bool, func, string} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import './_Styles.scss';

const propTypes = {
  // trigger: ReactComponentLike,
  className: string,
  title: string,
  content: any,
  setCloseOnDocumentClick: func,
  disabled: bool,
  large: bool,
  invalid: bool,
};

const defaultProps = {
  className: '',
  trigger: null,
  title: '',
  content: null,
  setCloseOnDocumentClick: () => {},
  disabled: false,
  large: false,
  medium: false,
  invalid: false,
};

const PopupSetting = ({
  className,
  trigger,
  title,
  content,
  setCloseOnDocumentClick: setParentCloseOnDocumentClick,
  disabled,
  medium,
  large,
  invalid,
  dropdownProps,
}) => {
  const {messenger} = useContext(BuilderContext) ?? {};

  const [isOpen, setIsOpen] = useState(dropdownProps?.isOpen ?? false);
  const [closeOnDocumentClick, setCloseOnDocumentClick] = useState(true);

  useEffect(() => {
    messenger?.sendInAppForegroundForce(isOpen);

    setParentCloseOnDocumentClick(!isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dropdown
      className={classNames('popup-setting', className, {large, medium})}
      position={['left top', 'left bottom']}
      offsetX={8}
      open={isOpen}
      disabled={disabled}
      closeOnDocumentClick={isOpen === true && closeOnDocumentClick}
      trigger={
        <div
          className={classNames('popup-setting-trigger-wrapper', {
            active: isOpen,
            invalid: invalid,
          })}
          onClick={() => setIsOpen(true)}
          closeOnDocumentClick={isOpen === true && closeOnDocumentClick}
        >
          {React.cloneElement(trigger, {disabled, active: isOpen})}
        </div>
      }
      {...dropdownProps}
      onClose={() => {
        dropdownProps?.onClose?.();
        setIsOpen(false);
      }}
      contentStyle={{zIndex: 1002}}
    >
      <div className="popup-setting-wrapper">
        <div className="popup-setting-header">
          <div className="popup-setting-title">{title}</div>
          <i className="icon-close" onClick={() => setIsOpen(false)} />
        </div>
        <Divider />
        <div className="popup-setting-items">
          {React.cloneElement(content, {
            setCloseOnDocumentClick: (v) => {
              setCloseOnDocumentClick(v);

              setParentCloseOnDocumentClick(false);
            },
          })}
        </div>
      </div>
    </Dropdown>
  );
};

PopupSetting.propTypes = propTypes;
PopupSetting.defaultProps = defaultProps;

export default PopupSetting;
