import React, {useContext} from 'react';
// import PlateEditor from '../PlateEditor/index_old';
import AdvancedEditor from 'components/MarkdownEditor/AdvancedEditor';
import {BuilderContext} from 'contextes/builder';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_USER,
} from 'services/steps';
import './_Styles.scss';
import PostBlockCta from './components/modals/Cta';
import PostTagSelector from './components/modals/PostTagSelector';
import PostBlockProfile from './components/modals/Profile';
import PostBuilderBar from './components/PostBuilderBar';
import PostThumbnail from './components/PostThumbnail';
import PostTitle from './components/PostTitle';

const Writer = () => {
  const {evolution, setEvolution, selectedLanguage} =
    useContext(BuilderContext);

  const step = evolution.steps[0] || {};
  const hasCta =
    step?.blocks?.find((b) => b.type === BLOCK_TYPE_PRIMARY_CTA) != null;
  const hasProfile =
    step?.blocks?.find((b) => b.type === BLOCK_TYPE_USER) != null;
  const hasTags =
    step?.blocks?.find((b) => b.type === BLOCK_TYPE_LABEL) != null;

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_BODY);
  const {value = '', rawValue = ''} = block || {};

  const translation = block?.translations?.find(
    (t) => t.language === selectedLanguage
  );

  const actualValue =
    selectedLanguage != null ? translation?.value ?? '' : value;
  const actualRawValue =
    selectedLanguage != null ? translation?.rawValue ?? '' : rawValue;

  const onChange = ({value, rawValue}) => {
    if (selectedLanguage != null) {
      const hasLanguage =
        block.translations?.find((t) => t.language === selectedLanguage) !=
        null;
      setEvolution({
        ...evolution,
        steps: [
          {
            ...evolution.steps[0],
            blocks: evolution.steps[0]?.blocks.map((b) => {
              if (b.type === BLOCK_TYPE_BODY) {
                return {
                  ...b,
                  translations: hasLanguage
                    ? b.translations.map((t) => {
                        if (t.language === selectedLanguage) {
                          return {
                            ...t,
                            value,
                            rawValue,
                          };
                        } else {
                          return t;
                        }
                      })
                    : [
                        ...(b?.translations || []),
                        {language: selectedLanguage, value, rawValue},
                      ],
                };
              }
              return b;
            }),
          },
        ],
      });
    } else {
      setEvolution({
        ...evolution,
        steps: [
          {
            ...evolution.steps[0],
            blocks: evolution.steps[0]?.blocks.map((b) => {
              if (b.type === BLOCK_TYPE_BODY) {
                return {...b, value, rawValue};
              }
              return b;
            }),
          },
        ],
      });
    }
  };

  return (
    <div className="writer-wrapper">
      <div className="writer">
        <PostBuilderBar />
        {hasProfile === true && <PostBlockProfile />}
        {hasTags === true && <PostTagSelector evolution={evolution} />}
        <PostThumbnail />
        <PostTitle />
        {/* <PlateEditor placeholder="Start typing..." /> */}
        <AdvancedEditor
          key={selectedLanguage}
          placeholder="Start typing..."
          value={actualValue}
          rawValue={actualRawValue}
          onChange={onChange}
        />
        {hasCta === true && <PostBlockCta />}
      </div>
    </div>
  );
};

export default Writer;
