import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import React, {memo, useCallback, useContext} from 'react';
import {Handle, Position} from 'reactflow';
import {BLOCK_TYPE_PRIMARY_CTA} from 'services/steps';
import {MODE_NAVIGATOR} from '../../PokeBuilderSidebar';
import './_Styles.scss';
import Step from './components/Step';

const StepNode = ({data, isConnectable}) => {
  const {selectedStepId, setSelectedStepId, setSelectedTriggerId, setMode} =
    useContext(BuilderContext);

  const handleClick = useCallback(() => {
    setSelectedStepId(data.step.uid);
    setSelectedTriggerId(null);
    setMode(MODE_NAVIGATOR);
  }, [data.step.uid, setSelectedStepId, setSelectedTriggerId, setMode]);

  const hasPrimaryCta = data.step.blocks.some(
    (block) => block.type === BLOCK_TYPE_PRIMARY_CTA && block.removed !== true
  );

  return (
    <div
      className={classNames('step-node-wrapper', {
        'is-selected': selectedStepId === data.step.uid,
      })}
      onClick={handleClick}>
      <Handle
        type="target"
        position={Position.Left}
        style={{background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      {hasPrimaryCta && (
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          style={{background: '#555'}}
          isConnectable={false}
          isConnectableStart={false}
        />
      )}
      <Step
        data={data}
        isSelected={selectedStepId === data.step.uid}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default memo(StepNode);
