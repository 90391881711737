import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import ReactFlow, {
  Background,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {generalSelector} from 'selectors';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
} from 'services/steps';
import {MODE_NAVIGATOR, MODE_TRIGGERS} from '../PokeBuilderSidebar';
import StepNode from './StepNode';
import StepPlaceholder from './StepPlaceholder';
import './_Styles.scss';
import {
  addNewStep,
  autoLayout,
  autoLayoutDragging,
  generateNodes,
} from './utils';

const nodeTypes = {
  stepNode: memo(StepNode),
  stepPlaceholder: memo(StepPlaceholder),
};

const proOptions = {
  account: 'paid-pro',
  hideAttribution: true,
};

export const LogicViewContext = createContext();

const LogicView = () => {
  const project = useSelector(generalSelector.getProject);
  const {
    evolution,
    setEvolution,
    selectedStepId,
    mode,
    setMode,
    setSelectedStepId,
    setSelectedTriggerId,
    setSelectedBlockType,
    isDraggingToAddStep,
  } = useContext(BuilderContext);

  const {setViewport, zoomTo} = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [containerSize, setContainerSize] = useState({width: 0, height: 0});
  const [leftPanelWidth, setLeftPanelWidth] = useState(0);
  const [rightPanelWidth, setRightPanelWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditingContentWithDelay, setIsEditingContentWithDelay] =
    useState(false);
  const [draggedNode, setDraggedNode] = useState(null);
  const [afterNode, setAfterNode] = useState(false); // use false here to know when to call autoLayoutDragging

  const currentViewedStepIdRef = useRef();
  const isDraggingRef = useRef(false);
  const evolutionRef = useRef(evolution);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let updatedNodes = JSON.parse(JSON.stringify(nodes));
  let updatedEdges = JSON.parse(JSON.stringify(edges));

  if (isDraggingRef.current !== true) {
    updatedNodes = autoLayout(nodes);
  } else if (afterNode !== false) {
    const draggingLayout = autoLayoutDragging(nodes, draggedNode, afterNode);
    updatedNodes = draggingLayout.nodes;
    updatedEdges = draggingLayout.edges;
  }

  const dependencySteps = JSON.stringify(
    useMemo(() => {
      const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;
      const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;

      const steps =
        isTour === true
          ? evolution?.tourSteps?.map((ts) => ts.steps).flat()
          : isSurvey === true
          ? evolution?.steps
          : [];
      const dependencySteps = steps.map((step) => {
        const primaryCta = step.blocks?.find(
          (block) => block.type === BLOCK_TYPE_PRIMARY_CTA && !block.removed
        );
        const triggers = step?.triggers
          ?.filter((trigger) =>
            trigger?.actions?.some(
              (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
            )
          )
          ?.map((trigger) => {
            const goToStepAction = trigger.actions.find(
              (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
            );

            return {
              triggerId: trigger.uid,
              stepId: goToStepAction?.step?.uid,
            };
          });

        return {
          stepId: step.uid,
          primaryCta: !!primaryCta,
          triggers: triggers,
        };
      });

      return dependencySteps;
    }, [evolution])
  );

  const updateNodes = useCallback(() => {
    if (isDraggingRef.current) {
      return;
    }

    const {nodes: newNodes, edges: newEdges} = generateNodes(evolution);
    setNodes(newNodes);
    setEdges(newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencySteps, setNodes, setEdges, isDragging]);

  const onInit = useCallback((rf) => {
    setReactFlowInstance(rf);
  }, []);

  const onNodeDrag = useCallback(
    (event, node) => {
      const draggedNode = node;

      // Sort nodes based on their horizontal position
      const sortedNodes = nodes
        .slice()
        .sort((a, b) => a.position.x - b.position.x);

      // Determine the node after which the dragged node is currently over
      let afterNode = null;

      const filteredNodes = sortedNodes
        .filter((n) => n.id !== draggedNode.id)
        .filter(
          (n) =>
            n.position.x + n.width / 2 - draggedNode.width / 2 <
            draggedNode.position.x
        );

      afterNode = filteredNodes[filteredNodes.length - 1];

      setAfterNode(afterNode);
    },
    [nodes]
  );

  const onNodeDragStart = (event, node) => {
    setIsDragging(true);
    setDraggedNode(node);
    setMode(null);
    setSelectedStepId(null);
  };

  const onNodeDragStop = (event, node) => {
    setIsDragging(false);
    setDraggedNode(null);
    setAfterNode(false);

    // Find the new position of the dragged node
    const draggedNode = nodes.find((n) => n.id === node.id);

    // Sort nodes based on their horizontal position
    const sortedNodes = nodes
      .slice()
      .sort((a, b) => a.position.x - b.position.x);

    // Find the index of the dragged node in the sorted list
    const draggedNodeIndex = sortedNodes.findIndex(
      (n) => n.id === draggedNode.id
    );

    // Determine the node after which the dragged node is dropped
    let afterNode = null;
    if (draggedNodeIndex > 0) {
      afterNode = sortedNodes[draggedNodeIndex - 1];
    }

    // Find the original and new index positions
    const originalIndex = nodes.findIndex((n) => n.id === draggedNode.id);
    const newIndex = afterNode
      ? nodes.findIndex((n) => n.id === afterNode.id) + 1
      : 0;

    // Adjust newIndex to account for removal of the dragged node
    const adjustedIndex = newIndex > originalIndex ? newIndex - 1 : newIndex;

    // Move the dragged node to its new position
    const reorderedNodes = Array.from(nodes);
    const [removedNode] = reorderedNodes.splice(originalIndex, 1);
    reorderedNodes.splice(adjustedIndex, 0, removedNode);

    // Update nodes state
    setNodes(reorderedNodes);

    // Update the steps in evolution
    const reorderedSteps = Array.from(evolution.tourSteps);
    const [removedStep] = reorderedSteps.splice(originalIndex, 1);
    reorderedSteps.splice(adjustedIndex, 0, removedStep);

    reorderedSteps.forEach((step, index) => {
      step.tourStepInfo = [index].join(';');
      step.tourIndexOrder = index;
      if (step.uid === removedStep.uid) {
        step.steps.forEach((s) => {
          s.reordered = true;
        });
      }
    });

    setEvolution({...evolution, tourSteps: reorderedSteps});
  };

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      // Sort nodes based on their horizontal position
      const sortedNodes = nodes
        .slice()
        .sort((a, b) => a.position.x - b.position.x);

      // Determine the node after which the dragged node is currently over
      let afterNode = null;
      const filteredNodes = sortedNodes.filter(
        (n) => n.position.x + n.width / 2 < position.x
      );

      afterNode = filteredNodes[filteredNodes.length - 1];

      setAfterNode(afterNode || {id: ''});
      setIsDragging(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      setAfterNode(false);
      setIsDragging(false);

      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      // Sort nodes based on their horizontal position
      const sortedNodes = nodes
        .slice()
        .sort((a, b) => a.position.x - b.position.x);

      // Determine the node after which the dragged node is currently over
      let afterNode = null;

      const filteredNodes = sortedNodes.filter(
        (n) => n.position.x + n.width / 2 < position.x
      );

      afterNode = filteredNodes[filteredNodes.length - 1];

      const {tourSteps, steps, newStep} = addNewStep({
        evolution: evolutionRef.current,
        afterStepId: afterNode?.id,
        project,
        type,
      });

      setEvolution({
        ...evolution,
        ...(tourSteps != null ? {tourSteps} : {}),
        ...(steps != null ? {steps} : {}),
      });
      setSelectedStepId(newStep.uid);
      setSelectedBlockType(null);
      setMode(MODE_NAVIGATOR);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  const handleEdgeClick = (event, edge) => {
    setSelectedStepId(edge.data.step.uid);
    if (edge.data.trigger) {
      setSelectedTriggerId(edge.data.trigger.uid);
      setSelectedBlockType(null);
      setMode(MODE_TRIGGERS);
    } else {
      setMode(MODE_NAVIGATOR);
      setSelectedBlockType(BLOCK_TYPE_PRIMARY_CTA);
    }
  };

  useEffect(() => {
    isDraggingRef.current = isDragging;
  }, [isDragging]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  useEffect(() => {
    if (isDraggingToAddStep !== true) {
      setIsDragging(false);
      setDraggedNode(null);
      setAfterNode(false);
    }
  }, [isDraggingToAddStep]);

  useEffect(() => {
    if (mode === MODE_TRIGGERS) {
      setLeftPanelWidth(windowWidth > 1600 ? 550 : windowWidth * 0.3);
      setRightPanelWidth(0);
    } else {
      setLeftPanelWidth(windowWidth > 1600 ? 450 : windowWidth * 0.25);
      setRightPanelWidth(windowWidth > 1600 ? 366 : windowWidth * 0.25);
    }
  }, [mode, windowWidth]);

  useEffect(() => {
    if (isDraggingRef.current) {
      return;
    }

    if (!selectedStepId && reactFlowInstance && nodes?.length) {
      if (isMounted) {
        zoomTo(0.5, {duration: 600});
      } else {
        reactFlowInstance.fitView({maxZoom: 1, duration: 600});
        setIsMounted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactFlowInstance, selectedStepId]);

  useEffect(() => {
    updateNodes();
  }, [updateNodes]);

  useEffect(() => {
    if (mode === MODE_NAVIGATOR) {
      setTimeout(() => {
        setIsEditingContentWithDelay(true);
      }, 1200);
    } else {
      setIsEditingContentWithDelay(false);
    }
  }, [mode]);

  const containerWidth = windowWidth - leftPanelWidth - rightPanelWidth;
  const containerHeight = windowHeight - 60; // 60 is the height of the header

  useEffect(() => {
    // if (isDragging) {
    //   return;
    // }

    if (
      currentViewedStepIdRef.current === selectedStepId &&
      containerSize.height === containerHeight &&
      containerSize.width === containerWidth
    ) {
      setContainerSize({
        height: containerHeight,
        width: containerWidth,
      });
      // commented to allow zooming back in after zooming out from the same step
      // return;
    }

    const selectedNode = nodes.find((node) => node.id === selectedStepId);
    const position = selectedNode?.position;

    if (
      selectedNode &&
      position?.x != null &&
      position?.y != null &&
      !isNaN(position.x) &&
      !isNaN(position.y)
    ) {
      if (isDragging === false) {
        setViewport(
          {
            x:
              -selectedNode.position.x +
              containerWidth / 2 -
              selectedNode.width / 2 +
              leftPanelWidth,
            y:
              -selectedNode.position.y +
              containerHeight / 2 -
              selectedNode.height / 2,
            zoom: 1,
          },
          {duration: 600}
        );
      }
      currentViewedStepIdRef.current = selectedStepId;
      setContainerSize({
        height: containerHeight,
        width: containerWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStepId, nodes, containerWidth, containerHeight, setViewport]);

  return (
    <LogicViewContext.Provider value={{draggedNode}}>
      <div
        className={classNames('logic-view', {
          'is-editing-content': mode === MODE_NAVIGATOR,
          'is-editing-triggers': mode === MODE_TRIGGERS,
          'is-dragging': isDragging,
        })}>
        <ReactFlow
          nodes={updatedNodes}
          // edges={edges}
          edges={updatedEdges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          minZoom={0.2}
          proOptions={proOptions}
          zoomOnDoubleClick={false}
          /**
           * on handlers
           */
          onInit={onInit}
          onPaneClick={() => {
            setMode(null);
            setSelectedStepId(null);
          }}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          // remove the following line to allow zooming back in and out without leaving content editing mode
          // onMove={() => {
          //   if (isEditingContentWithDelay) {
          //     setMode(null);
          //     setSelectedStepId(null);
          //   }
          // }}
          onEdgeClick={handleEdgeClick}
          onDrop={onDrop}
          onDragEnter={onDragOver}
          onDragOver={onDragOver}>
          <Background />
        </ReactFlow>
      </div>
    </LogicViewContext.Provider>
  );
};

const LogicViewWrapped = () => (
  <ReactFlowProvider>
    <LogicView />
  </ReactFlowProvider>
);

export default LogicViewWrapped;
