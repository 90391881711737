import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect} from 'react';
import {
  MODE_NAVIGATOR,
  MODE_TRIGGERS,
} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import './_Styles.scss';

const BuilderModeSwitch = () => {
  const {mode, setMode, selectedStepId, evolution, setSelectedStepId} =
    useContext(BuilderContext);

  const isFlowMode = mode === MODE_TRIGGERS;
  const isContentMode = !isFlowMode;

  useEffect(() => {
    if (mode !== MODE_NAVIGATOR) {
      return;
    }

    if (selectedStepId == null) {
      const tourSteps = evolution?.tourSteps || [];
      tourSteps.forEach((s) => {
        const [tourIndexOrder] = (s.tourStepInfo || '0;0;0').split(';');
        s.tourIndexOrder = tourIndexOrder;
      });
      tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
      setSelectedStepId(tourSteps[0]?.steps[0]?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <div className="builder-mode-switch">
      <Button
        className={mode === 'navigator' ? 'is-active' : ''}
        onClick={() => {
          if (isFlowMode !== true) {
            setMode(MODE_TRIGGERS);
          }
        }}
        iconLeft="isax isax-routing-25"
        light={isFlowMode}>
        Flow
      </Button>
      <Button
        className={mode === 'triggers' ? 'is-active' : ''}
        onClick={() => {
          if (isContentMode !== true) {
            setMode(MODE_NAVIGATOR);
          }
        }}
        iconLeft="isax isax-designtools"
        light={isContentMode}>
        Content
      </Button>
    </div>
  );
};

export default BuilderModeSwitch;
