import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import ModalLaunchBuilder from 'components/ModalLaunchBuilder';
import {GlobalContext} from 'contextes/Global';
import {sendSetParentTab} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {useInAppBuilder} from 'hooks/useInAppBuilder';
import {createContext, useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {isEventValid} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {trackerService} from 'services';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import './_Styles.scss';
import TrackerBuilderHeader from './components/BuilderHeader';
import TrackerAudience from './components/TrackerAudience';
import TrackerBuilderSingleEvent from './components/TrackerBuilderSingleEvent';
import TrackerBuilderSteps, {defaultStep} from './components/TrackerSteps';

export const CREATE_TRACKER_ISSUE = {
  NO_STEPS: 'NO_STEPS',
  NO_EVENTS: 'NO_EVENTS',
  INVALID_EVENT: 'INVALID_EVENT',
};

const defaultTracker = (type) => {
  return {
    name:
      type === TRACKER_TYPE_ACTIVATION_TRACKER
        ? 'New Activation Tracker'
        : type === TRACKER_TYPE_FEATURE_USAGE
        ? 'New Feature Usage Tracker'
        : '',
    description: '',
    type,
    steps:
      type === TRACKER_TYPE_ACTIVATION_TRACKER
        ? [defaultStep({name: 'Step 1'}), defaultStep({name: 'Step 2'})]
        : [],
    onTheFlyEvent: null,
    segments: [],
    onTheFlySegment: null,
  };
};

export const TrackerBuilderContext = createContext({
  tracker: null,
  setTracker: () => {},
  selectedStepId: null,
  setSelectedStepId: () => {},
  selectedEventId: null,
  setSelectedEventId: () => {},
});

const TrackerBuilder = () => {
  const match = useRouteMatch();

  const {trackerId} = match.params;

  const isNewTracker = [
    'new-activation-tracker',
    'new-feature-usage-tracker',
  ].includes(trackerId);
  const newTrackerType =
    trackerId === 'new-activation-tracker'
      ? TRACKER_TYPE_ACTIVATION_TRACKER
      : TRACKER_TYPE_FEATURE_USAGE;

  const [tracker, setTracker] = useState(
    isNewTracker ? defaultTracker(newTrackerType) : null
  );
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isEditingAudience, setIsEditingAudience] = useState(false);

  const {isFetching, refetch} = useQuery({
    queryKey: ['tracker', trackerId],
    queryFn: () => trackerService.getTracker({trackerId}),
    onSuccess: (data) => {
      setTracker(data);
    },
    refetchOnWindowFocus: false,
    enabled: isNewTracker !== true,
  });

  const isActivationTracker = tracker?.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker?.type === TRACKER_TYPE_FEATURE_USAGE;

  let isValid = true;
  let issue = null;

  if (isFeatureUsageTracker === true) {
    if (tracker.onTheFlyEvent != null) {
      if (
        isEventValid(
          tracker.onTheFlyEvent.conditions,
          tracker.onTheFlyEvent.logic
        ).isValid === false
      ) {
        isValid = false;
        issue = CREATE_TRACKER_ISSUE.INVALID_EVENT;
      }
    } else {
      if (tracker.events?.[0] == null) {
        isValid = false;
        issue = CREATE_TRACKER_ISSUE.NO_EVENTS;
      }
    }
  } else if (isActivationTracker === true) {
    if (tracker.steps?.length < 2) {
      isValid = false;
      issue = CREATE_TRACKER_ISSUE.NO_STEPS;
    }

    for (const step of tracker.steps) {
      if (step.onTheFlyEvent != null) {
        if (
          isEventValid(step.onTheFlyEvent.conditions, step.onTheFlyEvent.logic)
            .isValid === false
        ) {
          isValid = false;
          issue = CREATE_TRACKER_ISSUE.INVALID_EVENT;
        }
      } else {
        if (step.events?.length === 0) {
          isValid = false;
          issue = CREATE_TRACKER_ISSUE.NO_EVENTS;
        }
      }
    }
  }

  const {parentTabRef, childTabIdRef, handshakeDataRef} =
    useContext(GlobalContext);

  const {openNewTab} = useAcrossTabs({
    onHandshakeCallback: () => {
      sendSetParentTab();

      parentTabRef.current?.broadCastTo(
        childTabIdRef.current,
        handshakeDataRef.current
      );
    },
  });

  const {
    openInAppBuilderAt,
    isEditingInApp,
    isLaunchBuilderModalOpen,
    closeLaunchBuilderModal,
    goToEditInApp,
    stopInAppEditing,
  } = useInAppBuilder({openNewTab});

  return (
    <TrackerBuilderContext.Provider
      value={{
        tracker,
        setTracker,
        selectedStepId,
        setSelectedStepId,
        selectedEventId,
        setSelectedEventId,
        refetchTracker: refetch,
        onAddNewEventClick: () => {},
        isEditingAudience,
        setIsEditingAudience,
        isValid,
        issue,
      }}>
      <InAppBuilderContext.Provider
        value={{
          openInAppBuilderAt,
          isEditingInApp,
          isLaunchBuilderModalOpen,
          closeLaunchBuilderModal,
          goToEditInApp,
          stopInAppEditing,
        }}>
        <div className="s-builder-tracker fade-in">
          {isFetching === true ? (
            <DefaultLoader />
          ) : tracker != null ? (
            <>
              <TrackerBuilderHeader />
              <div className="tracker-builder-content-wrapper">
                <div className="tracker-builder-content">
                  {tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER && (
                    <TrackerBuilderSteps />
                  )}
                  {tracker.type === TRACKER_TYPE_FEATURE_USAGE && (
                    <TrackerBuilderSingleEvent
                      conditions={tracker.onTheFlyEvent?.conditions}
                      logic={tracker.onTheFlyEvent?.logic}
                      setConditions={(conditions) => {
                        setTracker((tracker) => ({
                          ...tracker,
                          onTheFlyEvent: {
                            ...tracker.onTheFlyEvent,
                            conditions,
                          },
                        }));
                      }}
                      setLogic={(logic) => {
                        setTracker((tracker) => ({
                          ...tracker,
                          onTheFlyEvent: {
                            ...tracker.onTheFlyEvent,
                            logic,
                          },
                        }));
                      }}
                    />
                  )}
                  <Divider />
                  <TrackerAudience />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {isLaunchBuilderModalOpen && (
          <ModalLaunchBuilder
            isOpen={isLaunchBuilderModalOpen}
            onRequestClose={() => {
              closeLaunchBuilderModal();
            }}
            onOpenUrl={(url) => openInAppBuilderAt(url)}
          />
        )}
      </InAppBuilderContext.Provider>
    </TrackerBuilderContext.Provider>
  );
};

export default TrackerBuilder;
