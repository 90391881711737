import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {bool} from 'prop-types';
import React, {useContext, useEffect} from 'react';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {F_STEP_IS_SELECTING_PRESET, STEP_TYPE_CHECKLIST} from 'services/steps';
import BlockManager from '../BlockManager';
import ChecklistBlockManager from '../ChecklistBlockManager';
import ChecklistNavigator from '../ChecklistNavigator';
import Navigator from '../Navigator';
import ThemeManager from '../ThemeManager';
import TranslationManager from '../TranslationManager';
import TriggerManager from '../TriggerManager';
import './_Styles.scss';

export const MODE_ADD_BLOCK = 'MODE_ADD_BLOCK';
export const MODE_NAVIGATOR = 'MODE_NAVIGATOR';
export const MODE_TRANSLATIONS = 'MODE_TRANSLATIONS';
export const MODE_LOGIC = 'MODE_LOGIC';
export const MODE_THEME = 'MODE_THEME';
export const MODE_TRIGGERS = 'MODE_TRIGGERS';
export const MODE_ADD_CONTENT = 'MODE_ADD_CONTENT';

const propTypes = {
  hideContent: bool,
};

const defaultProps = {
  hideContent: false,
};

const PokeBuilderSidebar = ({hideContent}) => {
  const {
    evolution,
    mode,
    setMode,
    selectedStep,
    setSelectedBlockType,
    setSelectedChecklistItemId,
  } = useContext(BuilderContext);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isChecklistStep = selectedStep?.type === STEP_TYPE_CHECKLIST;

  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );

  useEffect(() => {
    if (isSelectingPreset === true) {
      setMode(MODE_NAVIGATOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectingPreset]);

  const triggersCount = selectedStep?.triggers?.length || 0;

  const navigator = isChecklistStep ? <ChecklistNavigator /> : <Navigator />;
  const blockManager = isChecklistStep ? (
    <ChecklistBlockManager onClose={() => setMode(MODE_NAVIGATOR)} />
  ) : (
    <BlockManager onClose={() => setMode(MODE_NAVIGATOR)} />
  );

  return (
    <div
      className={classNames('poke-builder-sidebar-wrapper', {
        'is-selecting-preset': isSelectingPreset,
        'fade-in-left': isTour || isSurvey,
      })}>
      <div className="poke-builder-sidebar">
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_ADD_BLOCK,
          })}
          onClick={() => {
            if (isChecklistStep) {
              setSelectedBlockType(null);
              setSelectedChecklistItemId(null);
            }
            setMode(MODE_ADD_BLOCK);
          }}>
          <i
            className={
              mode === MODE_ADD_BLOCK
                ? 'isax isax-add-square5'
                : 'isax isax-add-square'
            }
          />
        </div>
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_NAVIGATOR,
          })}
          onClick={() => setMode(MODE_NAVIGATOR)}>
          <i
            className={
              mode === MODE_NAVIGATOR ? 'isax isax-layer5' : 'isax isax-layer'
            }
          />
        </div>
        {(isTour || isSurvey) && (
          <div
            className={classNames('sidebar-item', {
              selected: mode === MODE_TRIGGERS,
            })}
            onClick={() => setMode(MODE_TRIGGERS)}>
            <i
              className={
                mode === MODE_TRIGGERS
                  ? 'isax isax-hierarchy-35'
                  : 'isax isax-hierarchy-3'
              }
            />
            {triggersCount > 0 && (
              <div className="triggers-count body-4">{triggersCount}</div>
            )}
          </div>
        )}
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_THEME,
          })}
          onClick={() => setMode(MODE_THEME)}>
          <i
            className={
              mode === MODE_THEME ? 'isax isax-brush-25' : 'isax isax-brush-4'
            }
          />
        </div>
        <div
          className={classNames('sidebar-item', {
            selected: mode === MODE_TRANSLATIONS,
          })}
          onClick={() => setMode(MODE_TRANSLATIONS)}>
          <i
            className={
              mode === MODE_TRANSLATIONS
                ? 'isax isax-language-square5'
                : 'isax isax-language-square'
            }
          />
        </div>
      </div>

      {hideContent !== true && (
        <div className="poke-builder-sidebar-content-wrapper">
          {mode === MODE_NAVIGATOR && navigator}
          {mode === MODE_ADD_BLOCK && blockManager}
          {mode === MODE_TRANSLATIONS && (
            <TranslationManager onClose={() => setMode(MODE_NAVIGATOR)} />
          )}
          {mode === MODE_THEME && (
            <ThemeManager onClose={() => setMode(MODE_NAVIGATOR)} />
          )}
          {mode === MODE_TRIGGERS && (
            <TriggerManager onClose={() => setMode(MODE_NAVIGATOR)} />
          )}
        </div>
      )}
    </div>
  );
};

PokeBuilderSidebar.propTypes = propTypes;
PokeBuilderSidebar.defaultProps = defaultProps;

export default PokeBuilderSidebar;
