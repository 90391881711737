import classNames from 'classnames';
import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {validateAction} from 'helpers/step';
import {bool, func, object} from 'prop-types';
import {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {EVOLUTION_STATE_LIVE, EVOLUTION_TYPE_SURVEY} from 'services/evolution';
import {
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_EXPAND_CHECKLIST,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_MARK_CHECKLIST_TASK_COMPLETED,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SNOOZE
} from 'services/steps';
import {Swaler} from 'swaler';
import EditActionDropdown from '../EditActionDropdown';
import { parseSnoozeSettingsToText } from '../EditActionDropdown/components/SettingsSnooze';
import './_Styles.scss';

const propTypes = {
  action: object.isRequired,
  onChange: func,
  onDelete: func,
  isExpanded: bool,
  defaultOpen: bool,
};

const defaultProps = {
  onChange: () => {},
  onDelete: () => {},
  isExpanded: false,
  defaultOpen: false,
};

const logger = new Swaler('Action');

const Action = ({action, onChange, onDelete, isExpanded, defaultOpen}) => {
  const {evolution: evolutionContext} = useContext(BuilderContext);
  const isSurvey = evolutionContext.type === EVOLUTION_TYPE_SURVEY;

  const steps = isSurvey
    ? evolutionContext.steps.filter((s) => s.removed !== true)
    : evolutionContext.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: ts.tourStepInfo.split(';')[0],
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);
  steps.sort((a, b) => {
    // first sort on tourStepIndex and then on indexOrder
    const tourStepIndexA = a.tourStepIndex;
    const tourStepIndexB = b.tourStepIndex;
    const indexOrderA = a.indexOrder;
    const indexOrderB = b.indexOrder;

    if (tourStepIndexA < tourStepIndexB) {
      return -1;
    }
    if (tourStepIndexA > tourStepIndexB) {
      return 1;
    }
    if (indexOrderA < indexOrderB) {
      return -1;
    }
    if (indexOrderA > indexOrderB) {
      return 1;
    }
    return 0;
  });

  const {type, value} = action;

  const {data: experiences = [], isLoading} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const isGoToStep = type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP;
  const isRunJSCode = type === STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE;
  const isDimiss = type === STEP_CONDITION_ACTION_TYPE_DISMISS;
  const isLaunchExperience =
    type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE;
  const isNavigateTo = type === STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO;
  const isExpandChecklist =
    type === STEP_CONDITION_ACTION_TYPE_EXPAND_CHECKLIST;
  const isMarkChecklistTaskAsCompleted =
    type === STEP_CONDITION_ACTION_TYPE_MARK_CHECKLIST_TASK_COMPLETED;
  const isSnooze = type === STEP_CONDITION_ACTION_TYPE_SNOOZE;

  const [showEditDropdown, setShowEditDropdown] = useState(
    isDimiss !== true && defaultOpen
  );

  const selectedStep = steps.find((s) => s.uid === value);

  const isValid =
    validateAction(action, {
      hasNoPrevStep: steps.findIndex((s) => s === selectedStep) === 0,
    }).length === 0;

  const selectedExperience = experiences.find((e) => e.uid === value);

  let content = '',
    icon,
    iconClassName,
    title,
    header;

  if (isGoToStep) {
    icon = 'isax isax-arrow-right';
    iconClassName = 'go-to-step';
    header = 'Go to step';
    if (value === 'next-step') {
      icon = 'isax isax-arrow-right-2';
      title = 'To next step';
    } else if (value === 'previous-step') {
      icon = 'isax isax-arrow-left-3';
      title = 'To previous step';
    } else if (selectedStep != null) {
      title = selectedStep?.name;
    }
  } else if (isLaunchExperience) {
    icon = 'isax isax-routing';
    iconClassName = 'launch-experience';
    header = 'Launch experience';
    title = selectedExperience?.title || 'Select an experience';
  } else if (isNavigateTo) {
    icon = 'isax isax-send-2';
    iconClassName = 'navigate-to';
    header = 'Navigate to';
    title = value || 'Enter a URL';
  } else if (isRunJSCode) {
    icon = 'isax isax-document-code';
    iconClassName = 'run-js-code';
    title = 'Run JavaScript code';
  } else if (isDimiss) {
    icon = 'isax isax-close-square';
    iconClassName = 'dismiss';
    title = isSurvey ? 'Finish survey' : 'Dismiss flow';
  } else if (isExpandChecklist) {
    icon = 'isax isax-maximize-3';
    iconClassName = 'expand-checklist';
    title = 'Expand checklist';
    header = 'Action';
  } else if (isMarkChecklistTaskAsCompleted) {
    icon = 'isax isax-tick-square';
    iconClassName = 'mark-checklist-task-as-completed';
    title = 'Mark as completed';
    header = 'Action';
  } else if (isSnooze) {
    icon = 'isax isax-pause-circle';
    iconClassName = 'snooze';
    header = isSurvey ? 'Snooze survey' : 'Snooze tour'; // TODO: how to get is survey from props or context
    title = parseSnoozeSettingsToText(action)
  }

  content = (
    <>
      <div className={classNames('icon-wrapper', iconClassName)}>
        <i className={icon} />
      </div>
      <div className="content">
        {header && <div className="action-title body-4 n-500">{header}</div>}
        {title && <div className="action-subtitle body-3 n-800">{title}</div>}
      </div>
    </>
  );

  return (
    <EditActionDropdown
      action={action}
      setAction={(action) => onChange(action)}
      isOpen={showEditDropdown}
      onClose={() => setShowEditDropdown(false)}
      disabled={
        isDimiss === true ||
        isExpanded !== true ||
        isExpandChecklist === true ||
        isMarkChecklistTaskAsCompleted === true
      }
      trigger={
        <div
          className={classNames('trigger-action', {
            'is-invalid': isValid !== true,
            selected: showEditDropdown,
            'is-reduced': isExpanded !== true,
          })}
          onClick={() => {
            if (
              isDimiss === true ||
              isExpandChecklist === true ||
              isMarkChecklistTaskAsCompleted === true
            ) {
              return;
            }
            setShowEditDropdown(true);
          }}>
          {content}
          {isExpandChecklist !== true &&
            isMarkChecklistTaskAsCompleted !== true && (
              <div className="actions-wrapper">
                <Button
                  iconOnly
                  iconLeft="isax isax-trash"
                  danger
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onDelete();
                  }}
                />
              </div>
            )}
        </div>
      }
      position="right"
      offsetX={8}
    />
  );
};

Action.propTypes = propTypes;
Action.defaultProps = defaultProps;

export default Action;
