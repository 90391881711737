export const getPointerStyle = ({
  position,
  dimension,
  hintDimension,
  borderRadius,
}) => {
  let forcedOffsetX = null,
    forcedOffsetY = null,
    pointerPosition = 'top';
  const pointerOffset = 16.970562748477143 / 2;
  if (position != null) {
    const [primaryPosition, secondaryPosition] = position.split('-');
    if (secondaryPosition === 'left') {
      forcedOffsetX =
        -dimension.width / 2 +
        hintDimension.width / 2 +
        borderRadius +
        pointerOffset;
    } else if (secondaryPosition === 'right') {
      forcedOffsetX =
        dimension.width / 2 -
        hintDimension.width / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'top') {
      forcedOffsetY =
        dimension.height / 2 -
        hintDimension.height / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'bottom') {
      forcedOffsetY =
        -dimension.height / 2 +
        hintDimension.height / 2 +
        borderRadius +
        pointerOffset;
    }

    if (primaryPosition === 'right') {
      pointerPosition = 'left';
    } else if (primaryPosition === 'bottom') {
      pointerPosition = 'top';
    } else if (primaryPosition === 'left') {
      pointerPosition = 'right';
    } else if (primaryPosition === 'top') {
      pointerPosition = 'bottom';
    }
  }

  return {pointerPosition, forcedOffsetX, forcedOffsetY};
};
