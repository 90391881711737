import classnames from 'classnames';
import React, {useState} from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './_Styles.scss';

const SelectGroup = (props) => {
  const {
    hideDropdown,
    small = false,
    legend,
    styles = {},
    isSearchable = true,
    wrapperClassName = '',
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const classNames = classnames('jimo-select', props.className, {
    small,
  });
  const handleKeyDown = (e) => {
    const {onPressEnter = null} = props;

    if (onPressEnter != null) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        onPressEnter();
      }
    }
  };

  return (
    <div className={classnames('select-group-wrapper', wrapperClassName)}>
      {legend != null && (
        <div
          className={classnames('select-legend body-3', {
            disabled: props.isDisabled === true,
          })}>
          {legend}
        </div>
      )}
      <Select
        {...props}
        components={{
          DropdownIndicator() {
            return hideDropdown === true ? null : (
              <i className="icon-chevron-bottom"></i>
            );
          },
          GroupHeading: (props) => {
            const {data} = props;

            if (data.label == null) {
              return null;
            }
            return (
              <div className="jimo-select__group-heading">{props.children}</div>
            );
          },
          ...props.components,
        }}
        onKeyDown={handleKeyDown}
        className={classNames}
        classNamePrefix={classnames(props.classNamePrefix, 'jimo-select')}
        value={isSearchable && isDropdownOpen === true ? null : props.value}
        onMenuOpen={() => {
          setIsDropdownOpen(true);
          props?.onMenuOpen?.();
        }}
        onMenuClose={() => {
          setIsDropdownOpen(false);
          props?.onMenuClose?.();
        }}
        styles={{
          ...(styles || {}),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            ...(styles.menuPortal || {}),
          }),
        }}
        placeholder={
          props.placeholder ||
          (isSearchable === true ? 'Search...' : 'Select...')
        }
      />
    </div>
  );
};

export const Creatable = (props) => {
  const classNames = classnames('jimo-creatable', props.className);

  return (
    <CreatableSelect
      {...props}
      className={classNames}
      classNamePrefix="jimo-creatable"
    />
  );
};

export default SelectGroup;
