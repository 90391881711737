import AcrossTabs from 'across-tabs';
import {GlobalContext} from 'contextes/Global';
import {useContext, useEffect} from 'react';

const useAcrossTabs = ({onHandshakeCallback /*, onChildCommunication*/}) => {
  const {parentTabRef, childTabIdRef, onChildCommunicationRef} =
    useContext(GlobalContext);

  useEffect(() => {
    if (parentTabRef.current != null) {
      return;
    }

    const config = {
      onHandshakeCallback: onHandshakeCallback,
      onChildCommunication: (message) => {
        onChildCommunicationRef.current(message);
      },
    };

    const parent = new AcrossTabs.Parent(config);
    parentTabRef.current = parent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openNewTab = (url) => {
    const childTab = parentTabRef.current?.openNewTab({
      url,
      windowName: 'AcrossTab',
    });

    childTabIdRef.current = childTab.id;
  };

  return {
    openNewTab,
    refParent: parentTabRef,
    childTabId: childTabIdRef,
  };
};

export default useAcrossTabs;
