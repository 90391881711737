import {GlobalContext} from 'contextes/Global';
import {
  sendSwitchToChildTab,
  sendSwitchToParentTab,
  useIsExtensionInstalled,
} from 'helpers/utils';
import {useContext, useEffect, useRef, useState} from 'react';

const useInAppBuilder = ({openNewTab}) => {
  const {
    parentTabRef,
    childTabIdRef,
    onChildCommunicationRef,
    isLaunchBuilderModalOpen,
    setIsLaunchBuilderModalOpen,
    handshakeData,
    setHandshakeData,
  } = useContext(GlobalContext);

  const [isEditingInApp, setIsEditingInApp] = useState(false);

  const {isInstalled: isExtensionInstalled} = useIsExtensionInstalled();

  const handshakeDataRef = useRef();

  useEffect(() => {
    handshakeDataRef.current = handshakeData;
  }, [handshakeData]);

  const handleStopInAppEditing = async () => {
    try {
      const response = await sendSwitchToParentTab();

      if (response.status === 'tabsSwitched') {
        parentTabRef.current?.broadCastTo(childTabIdRef.current, {
          type: 'SET_MODE',
          data: {
            mode: 'MODE_IDLE',
          },
        });
      } else {
        console.log('Error: ', response);
        parentTabRef?.current.closeAllTabs();
      }
    } catch (error) {
      console.error('Error switching to parent tab:', error);
      parentTabRef?.current.closeAllTabs();
    }

    setIsEditingInApp(false);
    return;
  };

  const handleGoToEditInApp = async ({handshakeData, onChildCommunication}) => {
    const openTabs = parentTabRef.current?.getOpenedTabs() || [];
    const foundTab = openTabs.find((t) => t.id === childTabIdRef.current);

    setHandshakeData(handshakeData);

    console.log('Handle Go To Edit In App:', handshakeData);
    console.log('Is Extension Installed:', isExtensionInstalled, foundTab);
    /**
     * Case 1: Extension is installed and an existing tab is open
     */
    if (isExtensionInstalled && foundTab != null) {
      onChildCommunicationRef.current = onChildCommunication;
      parentTabRef.current?.broadCastTo(childTabIdRef.current, handshakeData);
      try {
        const response = await sendSwitchToChildTab();
        console.log('Handle response:', response);
        if (response.status === 'tabsSwitched') {
          return;
        } else {
          console.log('Error: ', response);
        }
      } catch (error) {
        console.error('Error switching to child tab:', error);
      }
    }

    /**
     * Case 2: No tab is open
     * Open a new tab
     */
    onChildCommunicationRef.current = onChildCommunication;
    setHandshakeData(handshakeData);
    setIsLaunchBuilderModalOpen(true);
  };

  return {
    openInAppBuilderAt: (url) => {
      setIsLaunchBuilderModalOpen(false);
      openNewTab(url);
    },
    isEditingInApp,
    isLaunchBuilderModalOpen: isLaunchBuilderModalOpen,
    closeLaunchBuilderModal: () => setIsLaunchBuilderModalOpen(false),
    goToEditInApp: handleGoToEditInApp,
    stopInAppEditing: handleStopInAppEditing,
  };
};

export {useInAppBuilder};
