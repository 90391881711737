import React, {useContext} from 'react';
import './_Styles.scss';
import Input from 'components/Input';
import Select from 'components/Select';
import {BuilderContext} from 'contextes/builder';

const durationOptions = [
  {value: 'second', content: 'Second'},
  {value: 'minute', content: 'Minute'},
  {value: 'hour', content: 'Hour'},
  {value: 'day', content: 'Day'},
  {value: 'week', content: 'Week'},
  {value: 'month', content: 'Month'},
];

const completionOptionsMap = {
  '-1': 'Until completed',
  1: '1 time',
  2: '2 times',
  3: '3 times',
  4: '4 times',
  5: '5 times',
};

const completionOptions = Object.entries(completionOptionsMap).map(
  ([value, label]) => ({
    value,
    label,
  })
);
//[
//{value: 'completed', label: 'Until completed'},
//{value: '1', label: '1 time'},
//{value: '2', label: '2 times'},
//{value: '3', label: '3 times'},
//{value: '4', label: '4 times'},
//{value: '5', label: '5 times'},
//];

const parseSnoozeValue = (value) => {
  if (value == null) {
    return {durationUnit: null, durationValue: null, completion: null};
  }
  const [durationUnit, durationValue, completion] = value.split(';');

  return {
    durationUnit,
    durationValue,
    completion,
  };
};

export const parseSnoozeSettingsToText = (action) => {
  if (action.value == null) return '';
  const {
    durationUnit = durationOptions[0].value,
    durationValue = 0,
    completion = completionOptions[0].value,
  } = parseSnoozeValue(action.value);
  return `For ${durationValue} ${durationUnit}${durationValue > 1 ? 's' : ''}, ${completionOptionsMap[completion]}`;
};

const SettingsSnooze = ({action, setAction}) => {
  const {evolution} = useContext(BuilderContext);

  const {
    durationUnit = durationOptions[0].value,
    durationValue = 0,
    completion = completionOptions[0].value,
  } = parseSnoozeValue(action.value);

  return (
    <div className="settings-snooze">
      <div className="subtitle">For every Snooze Tour</div>
      <div className="field-group">
        <div className="field">
          <text className="body-3">Snooze for</text>
          <Input
            small
            type="number"
            min={0}
            option
            optionValue={durationUnit}
            optionItems={durationOptions}
            onOptionSelected={(selectedDurationUnitValue) =>
              setAction({
                ...action,
                value: `${selectedDurationUnitValue};${durationValue};${completion}`,
                evolution,
              })
            }
            onChange={({target}) =>
              setAction({
                ...action,
                value: `${durationUnit};${parseInt(target.value, 10)};${completion}`,
                evolution,
              })
            }
            value={durationValue}
          />
        </div>
        <div className="field">
          <text className="body-3">Until</text>
          <Select
            small
            options={completionOptions}
            value={completionOptions.find((o) => o.value === completion)}
            onChange={(selectedCompletion) =>
              setAction({
                ...action,
                value: `${durationUnit};${durationValue};${selectedCompletion.value}`,
                evolution,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsSnooze;
