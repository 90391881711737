import Divider from 'components/Divider';
import Tooltip from 'components/Tooltip';
import React, {useContext} from 'react';
import {EventConditionOperand} from 'services/event';
import {EventConditionsEditorContext} from '../../../..';
import {ElementSelector} from '../ElementSelector';
import './_Styles.scss';

const SettingsElement = ({condition, setCondition}) => {
  const {onSelectElement} = useContext(EventConditionsEditorContext);

  const {operand} = condition;

  return (
    <div className="settings-element">
      <ElementSelector
        data={condition}
        onChange={(data) =>
          setCondition({
            ...condition,
            ...data,
          })
        }
        withMultiple
        onSelectElement={
          onSelectElement != null
            ? () => onSelectElement(condition.uid, {type: 'condition-element'})
            : null
        }
      />
      <Divider />
      <div className="section-item">
        <div className="section-item-title n-800">When</div>
        <div className="section-item-content">
          <div
            className="radio-options-wrapper"
            onChange={(e) =>
              setCondition({
                ...condition,
                operand: e.target.value,
              })
            }>
            <div className="option">
              <input
                type="radio"
                id="click"
                name="event"
                checked={operand === EventConditionOperand.CLICKED}
                value={EventConditionOperand.CLICKED}
              />
              Is clicked
            </div>
            <div className="option">
              <input
                type="radio"
                id="click"
                name="event"
                checked={operand === EventConditionOperand.MOUSE_DOWN}
                value={EventConditionOperand.MOUSE_DOWN}
              />
              Is mouse down{' '}
              <Tooltip
                dark
                className="support-event-mouse-down"
                position="top left"
                offsetY={8}
                trigger={<i className="isax isax-info-circle"></i>}>
                <span>Is mouse down</span> is an alternative to{' '}
                <span>is clicked</span>. Use it if you are having troubles
                making <span>is clicked</span> work properly on some elements of
                your app.
              </Tooltip>
            </div>
            <div className="option">
              <input
                type="radio"
                id="hover"
                name="event"
                checked={operand === EventConditionOperand.HOVERED}
                value={EventConditionOperand.HOVERED}
              />
              Is hovered
            </div>
            <div className="option">
              <input
                type="radio"
                id="present"
                name="event"
                checked={operand === EventConditionOperand.PRESENT}
                value={EventConditionOperand.PRESENT}
              />
              Is present
            </div>
            <div className="option">
              <input
                type="radio"
                id="click"
                name="event"
                checked={operand === EventConditionOperand.NOT_PRESENT}
                value={EventConditionOperand.NOT_PRESENT}
              />
              Is not present
            </div>
            <div className="option">
              <input
                type="radio"
                id="hover"
                name="event"
                checked={operand === EventConditionOperand.DISABLED}
                value={EventConditionOperand.DISABLED}
              />
              Is disabled
            </div>
            <div className="option">
              <input
                type="radio"
                id="present"
                name="event"
                checked={operand === EventConditionOperand.NOT_DISABLED}
                value={EventConditionOperand.NOT_DISABLED}
              />
              Is not disabled
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsElement;
