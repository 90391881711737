import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {generalSelector} from 'selectors';
import {StepSectionItem} from '../ContentManager';
import {addNewStep} from '../LogicView/utils';
import {MODE_NAVIGATOR} from '../PokeBuilderSidebar';
import './_Styles.scss';

const Hotspot = 'https://assets.usejimo.com/content-manager/hotspot.svg';
const Tooltip = 'https://assets.usejimo.com/content-manager/tooltip.svg';
const Modal = 'https://assets.usejimo.com/content-manager/modal.svg';
const Snippet = 'https://assets.usejimo.com/content-manager/modal.svg';

const LogicViewEmptyState = () => {
  const project = useSelector((state) => generalSelector.getProject(state));
  const {evolution, setEvolution, setSelectedStepId, setMode} =
    useContext(BuilderContext);

  const handleAddStep = (type) => {
    const {tourSteps, newStep} = addNewStep({
      evolution: evolution,
      project,
      type,
    });

    setEvolution({
      ...evolution,
      tourSteps,
    });
    setSelectedStepId(newStep.uid);
    setMode(MODE_NAVIGATOR);
  };

  return (
    <div className="logic-view-empty-state">
      <div className="empty-state-header">Add your first step</div>
      <div className="step-options">
        <StepSectionItem
          title="Tooltip"
          icon="isax isax-info-circle"
          image={Tooltip}
          type={TYPE_TOOLTIP}
          onClick={() => handleAddStep(TYPE_TOOLTIP)}
        />
        <StepSectionItem
          title="Hotspot"
          icon="isax isax-info-circle"
          image={Hotspot}
          type={TYPE_HOTSPOT}
          onClick={() => handleAddStep(TYPE_HOTSPOT)}
        />
        <StepSectionItem
          title="Snippet"
          icon="isax isax-info-circle"
          image={Snippet}
          type={TYPE_SNIPPET}
          onClick={() => handleAddStep(TYPE_SNIPPET)}
        />
        <StepSectionItem
          title="Modal"
          icon="isax isax-info-circle"
          image={Modal}
          type={TYPE_MODAL}
          onClick={() => handleAddStep(TYPE_MODAL)}
        />
      </div>
    </div>
  );
};

export default LogicViewEmptyState;
