import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_Styles.scss';

const Button = ({
  className,
  primary = false,
  secondary = true,
  tertiary = false,
  danger = false,
  light = false,
  thin = false,
  loading = false,
  disabled = false,
  iconLeft,
  iconRight,
  iconOnly = false,
  type = 'submit',
  text,
  children,
  onClick,
  innerRef,
  ...props
}) => {
  const _getClassName = () => {
    return classNames('btn', className, {
      'sw-1': disabled === false,
      'subtitle-3': thin === false,
      'subtitle-4': thin === true,
      'is-primary': primary,
      'is-secondary': secondary && primary === false && tertiary === false,
      'is-tertiary': tertiary,
      'is-default': danger === false && light === false,
      'is-danger': danger,
      'is-light': light,
      'is-thin': thin === true,
      'is-loading': loading,
      'is-disabled': isDisabled(),
      'left-icon': iconLeft != null,
      'right-icon': iconRight != null,
      'is-icon-only': iconOnly,
      icon: iconLeft != null || iconRight != null || iconOnly === true,
    });
  };

  const isDisabled = () => loading || disabled;

  const handleClick = (e) => {
    if (isDisabled() === false) {
      onClick(e);
    } else {
      e.preventDefault();
    }
  };

  const content = children != null ? children : text;

  return (
    <button
      ref={innerRef}
      className={_getClassName()}
      onClick={handleClick}
      type={type}
      {...props}>
      {iconLeft != null && <i className={`icon-left ${iconLeft}`} />}
      {content}
      {iconRight != null && <i className={`icon-right ${iconRight}`} />}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.any,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  iconOnly: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  light: false,
  iconOnly: false,
  onClick: () => {},
};

export default Button;
