import classNames from 'classnames';
import React, {memo} from 'react';
import './_Styles.scss';

const StepPlaceholder = () => {
  return (
    <div className={classNames('step-placeholder')}>
      <div className="icon-wrapper">
        <i className="icon-plus" />
      </div>
    </div>
  );
};

export default memo(StepPlaceholder);
