import {generalActions} from 'actions';
import Button from 'components/Button';
import {toastDanger, toastInfo, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_SLACK,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {slackService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import HelpSection from './components/HelpSection';
import SettingsSetup from './components/SettingsSetup';

const logger = new Swaler('SlackSettings');

const SlackSettings = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const project = useSelector(() => generalSelector.getProject());

  const [isSlackLoading, setIsSlackLoading] = useState(false);

  const {slack} = project;
  const isSlackActive = !!slack?.access_token;

  const {mutate: deleteSlack, isLoading: isLoadingSlackDelete} = useMutation(
    () => slackService.revokeToken(slack?.uid),
    {
      onError: (err) => {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error(`Failed to disconnect slack integration, ${code}`);
        toastDanger([title, message], {actions});
      },
      onSuccess: () => {
        toastInfo([
          'Integration disconnected',
          'Your integration with Slack has been disconnected!',
        ]);
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        dispatch(generalActions.uptProject({slack: null}));
      },
    }
  );

  useEffect(() => {
    if (location.pathname === ROUTE_SETTINGS_INTEGRATIONS_SLACK) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const state = params.get('state');

      if (code !== null && state !== null) {
        setIsSlackLoading(true);
        slackService
          .exchangeCodeForToken(project.uid, {
            code,
            state,
          })
          .then((slack) => {
            dispatch(generalActions.uptProject({slack}));
            toastSuccess([
              'Slack Workspace connected',
              'Jimo will now send survey responses to your selected channels in surveys',
            ]);
          })
          .catch((err) => {
            const {code, title, message, actions} =
              errorHelpers.parseError(err);

            logger.error(`Failed to exchange code for token, ${code}`);
            toastDanger([title, message], {actions});
            history.push(ROUTE_SETTINGS_INTEGRATIONS);
          })
          .finally(() => {
            params.delete('code');
            params.delete('state');
            history.replace({
              pathname: ROUTE_SETTINGS_INTEGRATIONS_SLACK,
              search: params.toString(),
            });
            setIsSlackLoading(false);
          });
      } else if (isSlackActive === false) {
        history.push(ROUTE_SETTINGS_INTEGRATIONS);
        toastInfo([
          'Integration not connected',
          "Slack integration isn't connected yet. Please connect the integration first.",
        ]);
      }
    }
  }, [location]);

  return (
    <SettingsBody className="slack-settings">
      <div className="slack-settings-header">
        <div className="left-side">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {
              history.push(ROUTE_SETTINGS_INTEGRATIONS);
            }}
          />
          <div className="title-3">Slack Integration</div>
        </div>
      </div>
      <div className="slack-settings-content">
        <div className="left-side">
          <SettingsSetup slack={slack} isSlackLoading={isSlackLoading} />
        </div>
        <div className="right-side">
          <HelpSection
            isDisconnecting={isLoadingSlackDelete}
            onDisconnect={deleteSlack}
            slack={slack}
          />
        </div>
      </div>
    </SettingsBody>
  );
};

export default SlackSettings;
