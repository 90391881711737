import classNames from 'classnames';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_SUCCESS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import './_Styles.scss';
import Choice from './imgs/survey/choice.svg';
import Concept from './imgs/survey/concept.svg';
import Interview from './imgs/survey/interview.svg';
import Nps from './imgs/survey/nps1.svg';
import Open from './imgs/survey/open.svg';
import Say from './imgs/survey/say.svg';
import Scale from './imgs/survey/scale8.svg';
import Slider from './imgs/survey/slider-s.svg';
import ThankYou from './imgs/survey/ty.svg';

const Hotspot = 'https://assets.usejimo.com/content-manager/hotspot.svg';
const Tooltip = 'https://assets.usejimo.com/content-manager/tooltip.svg';
const Modal = 'https://assets.usejimo.com/content-manager/modal.svg';
const Snippet = 'https://assets.usejimo.com/content-manager/modal.svg';

const propTypes = {};
const defaultProps = {};

const SurveyBlocks = [
  {
    title: 'NPS',
    image: Nps,
    type: STEP_TYPE_NPS,
    category: 'Ask',
  },
  {
    title: 'Multiple Choice',
    image: Choice,
    type: STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    category: 'Ask',
  },
  {
    title: 'Slider',
    image: Slider,
    type: STEP_TYPE_SLIDER,
    category: 'Ask',
  },
  {
    title: 'Opinion Scale',
    image: Scale,
    type: STEP_TYPE_OPINION_SCALE,
    category: 'Ask',
  },
  {
    title: 'Open Question',
    image: Open,
    type: STEP_TYPE_TEXT_LONG,
    category: 'Ask',
  },
  {
    title: 'Concept Test',
    image: Concept,
    type: STEP_TYPE_CONCEPT_TEST,
    category: 'Test',
  },
  {
    title: 'Interview Prompt',
    image: Interview,
    type: STEP_TYPE_INTERVIEW,
    category: 'Test',
  },
  {
    title: 'Text Block',
    image: Say,
    type: STEP_TYPE_TEXT_BLOCK,
    category: 'Say',
  },
  {
    title: 'Thank You',
    image: ThankYou,
    type: STEP_TYPE_SUCCESS,
    category: 'Say',
  },
];

export const StepSectionItem = ({
  title,
  description,
  icon,
  image,
  type,
  onClick,
  isSurvey,
}) => {
  const {setIsDraggingToAddStep} = useContext(BuilderContext);

  const onDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', type);
    event.dataTransfer.effectAllowed = 'move';
    setIsDraggingToAddStep(true);
  };

  const onDragEnd = (event) => {
    setIsDraggingToAddStep(false);
  };

  if (isSurvey) {
    return (
      <div className={classNames('section-item-survey')}>
        <div
          className="image-wrapper"
          draggable
          onDragStart={onClick != null ? null : onDragStart}
          onDragEnd={onClick != null ? null : onDragEnd}
          onClick={onClick || (() => {})}>
          <img src={image} alt="img" />
        </div>
        <div className="title subtitle-4 n-800">{title}</div>
      </div>
    );
  }

  return (
    <div
      className={classNames('section-item')}
      draggable
      onDragStart={onClick != null ? null : onDragStart}
      onDragEnd={onClick != null ? null : onDragEnd}
      onClick={onClick || (() => {})}>
      <div className="header">
        {icon && (
          <div className="icon-wrapper">
            <i className={icon} />
          </div>
        )}
        <div className="content">
          <div className="title subtitle-3 n-800">{title}</div>
          {description && (
            <div className="description body-4 n-700">{description}</div>
          )}
        </div>
      </div>
      {image && (
        <div className="image-wrapper">
          <img src={image} alt="img" />
        </div>
      )}
    </div>
  );
};

const ContentManager = () => {
  const {evolution} = useContext(BuilderContext);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

  return (
    <div className="content-manager-wrapper fade-in-left">
      <div
        className={classNames('content-manager', {
          'is-survey': isSurvey,
        })}>
        {isTour && (
          <>
            <div className="content-manager-header">
              <div className="content-manager-title">Start from scratch</div>
            </div>
            <div className="sections">
              <div className="section-list">
                <StepSectionItem
                  title="Tooltip"
                  icon="isax isax-info-circle"
                  image={Tooltip}
                  type={TYPE_TOOLTIP}
                />
                <StepSectionItem
                  title="Hotspot"
                  icon="isax isax-info-circle"
                  image={Hotspot}
                  type={TYPE_HOTSPOT}
                />
                <StepSectionItem
                  title="Snippet"
                  icon="isax isax-info-circle"
                  image={Snippet}
                  type={TYPE_SNIPPET}
                />
                <StepSectionItem
                  title="Modal"
                  icon="isax isax-info-circle"
                  image={Modal}
                  type={TYPE_MODAL}
                />
              </div>
            </div>
          </>
        )}
        {isSurvey && (
          <div className="content-wrapper">
            <div className="sections">
              <div className="section">
                <div className="section-title subtitle-3 n-700">Ask</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Ask').map(
                    (block) => (
                      <StepSectionItem
                        key={block.title}
                        title={block.title}
                        image={block.image}
                        type={block.type}
                        isSurvey
                      />
                    )
                  )}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Test</div>
                <div className="section-list">
                  {SurveyBlocks.filter(
                    (block) => block.category === 'Test'
                  ).map((block) => (
                    <StepSectionItem
                      key={block.title}
                      title={block.title}
                      image={block.image}
                      type={block.type}
                      isSurvey
                    />
                  ))}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Say</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Say').map(
                    (block) => (
                      <StepSectionItem
                        key={block.title}
                        title={block.title}
                        image={block.image}
                        type={block.type}
                        isSurvey
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContentManager.propTypes = propTypes;
ContentManager.defaultProps = defaultProps;

export default ContentManager;
