import Button from 'components/Button';
import UsersReachedDrawer from 'components/ListLastUsersReached/components/UsersReachedDrawer';
import dayjs from 'dayjs';
import {React, useEffect, useRef, useState} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import UsersLikedDrawer from 'scenes/Portal/scenes/Post/component/LastUsersLikedList/components/UsersLikedDrawer'; // i'll keep this here cause it's only specific to changelog
import './_Styles.scss';

const ChartTooltip = ({
  data,
  valueUnitName,
  isViewMoreable = false,
  onViewMoreClick,
}) => {
  const {value, timestamp} = data;
  return (
    <div className="chart-tooltip">
      <div className="date body-3">
        {dayjs(timestamp * 1000).format('MMM DD YYYY')}
      </div>
      <div className="value">
        {value} {valueUnitName}
        {value && value > 1 ? 's' : ''}
      </div>
      <div className="drawer-trigger">
        {isViewMoreable === true && value != null && value > 0 && (
          <Button tertiary onClick={onViewMoreClick}>
            View users
          </Button>
        )}
      </div>
    </div>
  );
};

export const BarChartType = {
  SUBMISSION: 'submission',
  LIKE: 'like',
};

const BarChartComponent = ({
  data,
  type = BarChartType.SUBMISSION,
  evolution = null,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [areaHovered, setAreaHovered] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [breakdownDate, setBreakdownDate] = useState(null);

  const tooltipHoveredRef = useRef();
  const areaHoveredRef = useRef();

  useEffect(() => {
    tooltipHoveredRef.current = tooltipHovered;
  }, [tooltipHovered]);

  useEffect(() => {
    areaHoveredRef.current = areaHovered;
  }, [areaHovered]);

  const isViewMoreable = evolution != null && type === BarChartType.LIKE; // for now drawer only on likes

  return (
    <div className="bar-chart">
      {tooltipPosition != null && (
        <div
          style={{
            zIndex: '1',
            position: 'absolute',
            left: tooltipPosition.x - 20,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onMouseEnter={() => {
            setTooltipHovered(true);
          }}
          onMouseLeave={() => {
            setTooltipHovered(false);

            setTimeout(() => {
              if (areaHoveredRef.current === true) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}>
          <ChartTooltip
            data={tooltipData}
            onViewMoreClick={() => {
              setIsDrawerOpen(true);
              setBreakdownDate(tooltipData?.timestamp);
            }}
            valueUnitName={type}
            isViewMoreable={isViewMoreable}
          />
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          onMouseEnter={() => setAreaHovered(true)}
          onMouseMove={(e) => {
            setTooltipPosition(e.activeCoordinate);
            setTooltipData({
              timestamp: e.activePayload?.[0].payload.timestamp,
              value: e.activePayload?.[0].payload.value,
            });
          }}
          onMouseLeave={() => {
            setAreaHovered(false);
            setTimeout(() => {
              if (
                tooltipHoveredRef.current === true ||
                areaHoveredRef.current === true
              ) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}>
          <CartesianGrid horizontal vertical={false} stroke="#0000001a" />
          <Tooltip cursor={{fill: 'transparent'}} content={() => <></>} />
          <XAxis
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            axisLine={false}
            tickLine={false}
            tickCount={3}
            name="Date"
            tickFormatter={(unixTime) =>
              dayjs(unixTime * 1000).format('MMM DD')
            }
            type="number"
          />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
            domain={['auto', 'auto']}
            type="number"
            allowDecimals={false}
          />
          <Bar
            onClick={() => {
              setIsDrawerOpen(true);
              setBreakdownDate(tooltipData?.timestamp);
            }}
            style={isViewMoreable ? {cursor: 'pointer'} : {}}
            dataKey="value"
            fill="#BCDDFF"
            activeBar={<Rectangle fill="#1260eb" stroke="#1260eb" />}
          />
        </BarChart>
      </ResponsiveContainer>
      {isViewMoreable &&
        isDrawerOpen === true &&
        (type === BarChartType.LIKE ? (
          <UsersLikedDrawer
            isOpen={isDrawerOpen}
            onRequestClose={() => setIsDrawerOpen(false)}
            evolution={evolution}
            defaultStartDate={dayjs(breakdownDate * 1000)
              .startOf('day')
              .toDate()}
            defaultEndDate={dayjs(breakdownDate * 1000)
              .endOf('day')
              .toDate()}
          />
        ) : (
          <UsersReachedDrawer
            isOpen={isDrawerOpen}
            onRequestClose={() => setIsDrawerOpen(false)}
            evolution={evolution}
            defaultStartDate={dayjs(breakdownDate * 1000)
              .startOf('day')
              .toDate()}
            defaultEndDate={dayjs(breakdownDate * 1000)
              .endOf('day')
              .toDate()}
          />
        ))}
    </div>
  );
};

export default BarChartComponent;
